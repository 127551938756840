<template>
  <div class="p-4">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-12 text-left">
            <h4>Bankruptcy check</h4>
          </div>
        </div>
      </div>

      <div class="card-body">
        <!-- Desktop view -->
        <div class="desktop-view">
          <div class="search-row">
            <div class="search-box">
              <div class="input-group form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  aria-describedby="basic-addon2"
                  v-model.lazy="search"
                  v-on:keyup.enter="fnSearch()"
                />

                <div class="input-group-append">
                  <span
                    class="input-group-text"
                    id="basic-addon2"
                    @click="fnSearch()"
                    >Search....</span
                  >
                </div>
                <label for="paginate" class="floatingLabel">Search ...</label>
              </div>
            </div>

            <div class="export-box">
              <div class="filter-box">
                <div class="status-box">
                  <div class="form-group">
                    <select
                      v-model="selectedStatus"
                      class="form-control sits_select"
                    >
                      <option value="">All</option>
                      <option value="1">Draft</option>
                      <option value="2">New</option>
                      <option value="3">More Information Required</option>
                      <option value="7">Ready to Check</option>
                      <option value="4">Waiting For Police Review</option>
                      <option value="5">Completed</option>
                      <option value="6">Cancelled</option>
                    </select>
                    <label for="paginate" class="floatingLabel">Status</label>
                  </div>
                </div>

                <div class="date-box">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="date"
                      id="dateOfBirth"
                      autocomplete="off_dateOfBirth"
                      placeholder=" "
                      v-model="fromDate"
                    />
                    <label class="floatingLabel" for="dateOfBirth"
                      >From date</label
                    >
                  </div>
                </div>
                <div class="date-box">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="date"
                      id="dateOfBirth"
                      autocomplete="off_dateOfBirth"
                      placeholder=" "
                      v-model="toDate"
                    />
                    <label class="floatingLabel" for="dateOfBirth"
                      >To date</label
                    >
                  </div>
                </div>
                <div>
                  <button class="btn btn-sm btn-info" @click="fnSearch()">
                    Filter
                  </button>
                </div>
                <div>
                  <button
                    class="btn btn-sm btn-info"
                    @click="fnExport()"
                    :disabled="isExport"
                  >
                    Export
                  </button>
                </div>
                <div>
                  <button class="btn btn-sm btn-info" @click="fnClear()">
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Mobile view -->
        <div class="mobile-view">
          <div class="search-row">
            <div class="row">
              <div class="col-12">
                <div class="input-group form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    aria-describedby="basic-addon2"
                    v-model.lazy="search"
                    v-on:keyup.enter="fnSearch()"
                  />

                  <div class="input-group-append">
                    <span
                      class="input-group-text"
                      id="basic-addon2"
                      @click="fnSearch()"
                      >Search....</span
                    >
                  </div>
                  <label for="paginate" class="floatingLabel">Search ...</label>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <select
                    v-model="selectedStatus"
                    class="form-control sits_select"
                  >
                    <option value="">All</option>
                    <option value="1">Draft</option>
                    <option value="2">New</option>
                    <option value="3">More Information Required</option>
                    <option value="7">Ready to Check</option>
                    <option value="4">Waiting For Review</option>
                    <option value="5">Completed</option>
                    <option value="6">Cancelled</option>
                  </select>
                  <label for="paginate" class="floatingLabel">Status</label>
                </div>
              </div>
              <div class="col-12">
                <div class="filter-box">
                  <div class="date-box">
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="date"
                        id="dateOfBirth"
                        autocomplete="off_dateOfBirth"
                        placeholder=" "
                        v-model="fromDate"
                      />
                      <label class="floatingLabel" for="dateOfBirth"
                        >From date</label
                      >
                    </div>
                  </div>
                  <div class="date-box">
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="date"
                        id="dateOfBirth"
                        autocomplete="off_dateOfBirth"
                        placeholder=" "
                        v-model="toDate"
                      />
                      <label class="floatingLabel" for="dateOfBirth"
                        >To date</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="filter-box-right">
                  <div>
                    <button class="btn btn-sm btn-info" @click="fnSearch()">
                      Filter
                    </button>
                  </div>
                  <div>
                    <button
                      class="btn btn-sm btn-info"
                      @click="fnExport()"
                      :disabled="isExport"
                    >
                      Export
                    </button>
                  </div>
                  <div>
                    <button class="btn btn-sm btn-info" @click="fnClear()">
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="table-responsive table-sm policeCheckList">
          <!-- <table class="table table-bordered table-striped table-hover"> -->
          <table
            class="v-datatable-light table table-bordered table-hover table-striped table-center"
          >
            <thead>
              <tr>
                <th class="header-item header-column-0">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('id')"
                    >Id</a
                  >
                  <span v-if="sortDirection == 'desc' && sortField == 'id'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span v-if="sortDirection == 'asc' && sortField == 'id'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>

                <th class="header-item header-column-0">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('project_id')"
                    >Project Id</a
                  >
                  <span
                    v-if="sortDirection == 'desc' && sortField == 'project_id'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span
                    v-if="sortDirection == 'asc' && sortField == 'project_id'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>

                <th class="header-item header-column-1">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('first_name')"
                    >First name</a
                  >
                  <span
                    v-if="sortDirection == 'desc' && sortField == 'first_name'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span
                    v-if="sortDirection == 'asc' && sortField == 'first_name'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-2">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('last_name')"
                    >Last name</a
                  >
                  <span
                    v-if="sortDirection == 'desc' && sortField == 'last_name'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span
                    v-if="sortDirection == 'asc' && sortField == 'last_name'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-3">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('email')"
                    >Email</a
                  >
                  <span v-if="sortDirection == 'desc' && sortField == 'email'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span v-if="sortDirection == 'asc' && sortField == 'email'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-5 text-center">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('stakeholder')"
                    >Stakeholder</a
                  >
                  <span
                    v-if="sortDirection == 'desc' && sortField == 'stakeholder'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span
                    v-if="sortDirection == 'asc' && sortField == 'stakeholder'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>

                <th class="header-item header-column-5 text-center">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('status')"
                    >Status</a
                  >
                  <span v-if="sortDirection == 'desc' && sortField == 'status'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span v-if="sortDirection == 'asc' && sortField == 'status'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-7 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(row, index) in getBankruptcyListPaginate.data">
                <tr :key="(row, index)">
                  <td class="column-0">{{ row.id }}</td>
                  <td class="column-0">{{ row.project_id }}</td>
                  <td class="column-1">{{ row.cc_first_name }}</td>
                  <td class="column-2">{{ row.cc_last_name }}</td>
                  <td class="column-4">{{ row.cc_email }}</td>
                  <td class="column-6">{{ row.stakeholder }}</td>

                  <td class="column-7 text-center">
                    <span v-if="row.status == 1" class="badge badge-secondary"
                      >Draft</span
                    >
                    <span v-if="row.status == 2" class="badge badge-primary"
                      >New</span
                    >
                    <span v-if="row.status == 3" class="badge badge-warning"
                      >MIR</span
                    >
                    <span v-if="row.status == 4" class="badge badge-info"
                      >WFPR</span
                    >
                    <span v-if="row.status == 5" class="badge badge-success"
                      >Completed</span
                    >
                    <span v-if="row.status == 6" class="badge badge-dark"
                      >Cancelled</span
                    >
                    <span v-if="row.status == 7" class="badge badge-light"
                      >Ready to Check</span
                    >
                  </td>
                  <td class="column-8">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <!-- View job -->
                      <a
                        v-scroll-to="'#bankruptcyView'"
                        @click="fnGoCheckView(row, 'view')"
                        class="d-inline pr-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="View"
                      >
                        <i class="far fa-eye text-info fs-800"></i>
                      </a>

                      <!-- Edit job -->
                      <button
                        v-scroll-to="'#bankruptcyEdit'"
                        class="btn btn-sm d-inline pr-1"
                        @click="fnGoCheckEdit(row, 'edit')"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit"
                      >
                        <i class="far fa-edit text-info fs-800"></i>
                      </button>

                      <!-- download certificate -->
                      <button
                        v-scroll-to="'#bankruptcyCertificate'"
                        class="btn btn-sm d-inline pr-1"
                        @click="fnGoCertificate(row, 'certificate')"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Certificate"
                      >
                        <i class="fas fa-file-invoice text-info fs-800"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </template>
              <tr>
                <td colspan="10">
                  <div class="sits-pagination-row">
                    <div class="items-per-page">
                      <div class="pr-2">
                        <p class="mb-0">Items per page</p>
                      </div>
                      <div class="pr-5">
                        <select
                          v-model="paginate"
                          class="form-control sits_select"
                        >
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>

                    <div>
                      <pagination
                        :limit="5"
                        :data="getBankruptcyListPaginate"
                        @pagination-change-page="fnPaginate"
                      ></pagination>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <div v-if="action == 'view'" id="bankruptcyView">
            <appCheckBankruptcyView
              :listCategory="'list'"
              :propsData1="getUserFromId"
              :propsData2="getBankruptcyRecord"
              :noteId="getBankruptcyRecord.noteId"
              :notePublicId = getBankruptcyRecord.notePublicId
            ></appCheckBankruptcyView>
          </div>

          <div v-if="action == 'edit'" id="bankruptcyEdit">
            <appCheckBankruptcy
              :projectId="projectId"
              :productId="productId"
              :userId="userId"
            ></appCheckBankruptcy>
          </div>

          <div v-if="action == 'certificate'" id="bankruptcyCertificate">
            <appCheckBankruptcyCertificate
              :propsData1="getUserFromId"
              :propsData2="getBankruptcyRecord"
            ></appCheckBankruptcyCertificate>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../../store/modules/base.module";
import * as authService from "../../services/auth.service";
import pagination from "laravel-vue-pagination";
import * as messageService from "../../services/message.service";
import { mapGetters, mapActions } from "vuex";
import appCheckBankruptcyView from "../../components/checkBankruptcy/checkBankruptcyView.vue";
import appCheckBankruptcy from "../../components/checkBankruptcy/checkBankruptcy.vue";
import appCheckBankruptcyCertificate from "../../components/checkBankruptcy/checkBankruptcyCertificate.vue";

export default {
  name: "BankruptcyList",
  components: {
    pagination,
    appCheckBankruptcyView,
    appCheckBankruptcy,
    appCheckBankruptcyCertificate,
  },
  data() {
    return {
      loginUser: {},
      action: "",
      productId: 0,
      projectId: 0,
      userId: 0,

      bankruptcyListPaginate: {},
      paginate: 15,
      currentPage: 1,
      belongsTo: "",
      search: "",
      selectedStatus: "",
      fromDate: "",
      toDate: "",
      sortDirection: "desc",
      sortField: "id",
      isExport: true,
    };
  },
  computed: {
    ...mapGetters([
      "getNotesList",
      "getBankruptcyRecord",
      "getUserFromId",
      "getBankruptcyListPaginate",
    ]),
    paginateObj() {
      let obj = {};
      obj = {
        currentPage: this.currentPage,
        paginate: this.paginate,
        belongsTo: this.belongsTo,
        search: this.search,
        selectedStatus: this.selectedStatus,
        fromDate: this.fromDate,
        toDate: this.toDate,
        sortDirection: this.sortDirection,
        sortField: this.sortField,
      };
      return obj;
    },
  },
  methods: {
    ...mapActions([
      "fetchNotesList",
      "fetchNotesPublicList",
      "fetchBankruptcyRecord",
      "fetchUserFromId",
      "fetchBankruptcyRecordListPaginate",
    ]),

    fnChangeSort(field) {
      if (this.sortField == field) {
        this.sortDirection = this.sortDirection == "asc" ? "desc" : "asc";
      } else {
        this.sortField = field;
      }
      this.fetchBankruptcyRecordListPaginate(this.paginateObj);
    },
    fnPaginate: async function (currentPage = 1) {
      try {
        this.currentPage = currentPage;
        this.fetchBankruptcyRecordListPaginate(this.paginateObj);
      } catch (error) {
        const obj = {
          type: "error",
          title: error.response.data.error.name,
          text: error.response.data.error.message,
          footer: "Error code: " + error.response.data.error.statusCode,
        };

        messageService.fnSweetAlertDisplay(obj);
      }
    },
    // Export Police check list Summary into excel
    getExportPoliceCheckListSummary: function () {
      let belongsTo = "";
      let search = "";
      let selectedStatus = "";
      let fromDate = "";
      let toDate = "";

      if (this.belongsTo == "") {
        belongsTo = null;
      } else {
        belongsTo = this.belongsTo;
      }
      if (this.search == "") {
        search = null;
      } else {
        search = this.search;
      }
      if (this.selectedStatus == "") {
        selectedStatus = null;
      } else {
        selectedStatus = this.selectedStatus;
      }
      if (this.fromDate == "") {
        fromDate = null;
      } else {
        fromDate = this.fromDate;
      }
      if (this.toDate == "") {
        toDate = null;
      } else {
        toDate = this.toDate;
      }

      window.open(
        `${store.state.apiURL}/police-check/list-export/${belongsTo}/${search}/${selectedStatus}/${fromDate}/${toDate}`
      );

      messageService.fnSweetAlertSuccessToast(
        "Police Check Summary",
        "Downloaded successfully"
      );
    },

    fnSearch() {
      this.isExport = true;
      this.fetchBankruptcyRecordListPaginate(this.paginateObj);
      this.isExport = false;
    },

    // EXport Police check list summary
    fnExport() {
      this.getExportPoliceCheckListSummary();
      this.isExport = true;
    },
    // To Clear Search keys
    fnClear() {
      this.currentPage = 1;
      this.search = "";
      this.selectedStatus = "";
      this.fromDate = "";
      this.toDate = "";
      this.sortDirection = "desc";
      this.sortField = "id";
      this.isExport = true;
      this.fetchBankruptcyRecordListPaginate(this.paginateObj);
    },

    fnGoCheckView(row, action) {
      this.action = action;
      this.fetchUserFromId(row.cc_id);
      this.fetchBankruptcyRecord(row.project_id);

      // To load relevant notes
      this.fetchNotesList(row.noteId);
      this.fetchNotesPublicList(row.notePublicId);
    },
    fnGoCheckEdit(row, action) {
      this.action = action;
      this.productId = 8;
      this.projectId = row.project_id;
      this.userId = row.cc_id;
    },

    /**
     * Download or print Each check Certificate
     * @param row - row data
     * @param action - action via view|edit|certificate
     */
    fnGoCertificate(row, action) {
      this.action = action;

      this.fetchUserFromId(row.cc_id);
      this.fetchBankruptcyRecord(row.project_id);
    },
  },
  created() {
    this.loginUser = authService.getUserFromToken();
    this.fetchBankruptcyRecordListPaginate(this.paginateObj);
  },
};
</script>

<style lang="scss" scoped>
.sits-btn-round.btn.btn-info {
  border-radius: 50% !important;
  padding-top: 0.2rem !important;
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
}
.search-row {
  display: flex;
  justify-content: space-between;

  .search-box {
    width: 450px;
  }

  .filter-box {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: baseline;
  }

  .export-box {
    display: flex;
  }

  .status-box {
    width: 350px;
  }
  .date-box {
    max-width: 200px;
  }

  .input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.275rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #20bcee;
    border: 0px solid #ced4da;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
}
</style>
